export function videoTemplate(obj) {
  const { title = '', url = '' } = obj;
  const isFirstLesson = title.startsWith('Урок 1.');
  const css = isFirstLesson ? 'firstLesson' : '';
  return `
      <div class="block ${css}" data-url="${url}">
        <p class="title">${title}</p>
      </div>
      `;
}
export function videosTemplate(arr) {
  return arr.map(videoTemplate).join('');
}
export function frameTemplate(url) {
  const arr = url
    .split('\n')
    .map(url => {
      return `<iframe
        src="${url}"
        style="border: 0; height: 360px; width: 640px; max-width: 100%"
        allowfullscreen="true"
        allow="encrypted-media"
        data-gtm-yt-inspected-7="true"
      ></iframe>`;
    })
    .join('');
  return arr;
}
