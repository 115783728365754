import { fetchList } from './scripts/api';
import { frameTemplate, videosTemplate } from './scripts/render';

const refs = {
  videoList: document.querySelector('.js-list'),
  frameList: document.querySelector('.js-frames'),
};

document.addEventListener('DOMContentLoaded', () => {
  fetchList().then(data => {
    const arr = data.sort((a, b) => {
      const number1 = +a.title.split('.')[0].split(' ')[1] || 999;
      const number2 = +b.title.split('.')[0].split(' ')[1] || 999;
      a.module = a.module || '';
      b.module = b.module || '';

      if (a.module === b.module) {
        return number1 - number2;
      } else {
        return a.module.localeCompare(b.module);
      }
    });
    const markup = videosTemplate(arr);
    refs.videoList.innerHTML = markup;
  });
});

refs.videoList.addEventListener('click', e => {
  if (e.target === e.currentTarget) return;
  const baseElem = e.target.closest('.block');
  const url = baseElem.dataset.url;
  const markup = frameTemplate(url);
  refs.frameList.innerHTML = markup;
});
